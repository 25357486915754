function CardDescription({ descriptionTitle, setOpenDescriptionCard }) {
    function closeDescriptionWindow(){
        setOpenDescriptionCard(false)
    }
  return (
    <div className="buyListBoxPage" onClick={()=>setOpenDescriptionCard(false)}>
      <section className="buyListBox" onClick={(e) => e.stopPropagation()}>
        <p className="descriptionCardTitle">{descriptionTitle}</p>
        <span className="iconClose" onClick={closeDescriptionWindow}></span>
      </section>
    </div>
  );
}
export default CardDescription;

import { Link, useLocation, useNavigate } from "react-router-dom";
import telegramGroup from "../../images/telegram_logo_icon_257011.png";
import vk from "../../images/vk_logo_icon_257013.png";
import buyIcon from "../../images/bag_buy.png";
import homePageIcon from "../../images/homePageIcon.png";

function LinkTab() {
  const location = useLocation();
  const navigate = useNavigate();

  function openMobile() {
    document.querySelector(".mobileLinkBox-open").style.display = "flex";
  }

  function closeMobile() {
    document.querySelector(".mobileLinkBox-open").style.display = "none";
  }

  const handleAnchorLinkClick = () => {
    if (location.pathname === "/products") {
      return navigate("/");
    }
    const section = document.querySelector("#sectionBuy");
    if (section) {
      return section.scrollIntoView({ behavior: "smooth" });
    }
    return;
  };
  return (
    <>
      <section className="infoBox">
        <article className="contactList">
          <Link
            to="https://t.me/kikiasonasbakery"
            className="linkIcon"
            target="_blank"
          >
            <img
              className="linkIcon_img"
              src={telegramGroup}
              alt="Ссылка телеграм"
            />
            <p className="linkIconTitle">чат</p>
          </Link>
          <Link to="https://vk.com/kikiasonasbakery" className="linkIcon" target="_blank">
            <img className="linkIcon_img" src={vk} alt="Ссылка телеграм" />
            <p className="linkIconTitle">группа</p>
          </Link>
          <Link
            to="https://t.me/asonasbakerykiki"
            className="linkIcon"
            target="_blank"
          >
            <img
              className="linkIcon_img"
              src={telegramGroup}
              alt="Ссылка телеграм"
            />
            <p className="linkIconTitle">группа</p>
          </Link>
        </article>

        <article
          className="contactList contactListMobileCatalog"
          onClick={handleAnchorLinkClick}
        >
          <div className="linkIcon linkIconColorPulse">
            <img
              className="linkIcon_img"
              src={location.pathname === "/products" ? homePageIcon : buyIcon}
              alt={
                location.pathname === "/products"
                  ? "Ссылка на главную страницу"
                  : "Ссылка на каталог товаров"
              }
            />
            <p className="productCatalogTitle">
              {location.pathname === "/products" ? "главная" : "каталог"}
            </p>
          </div>
        </article>
        <span className="mailBox">
          <a className="mailTitle" href="mailto:kikiasonasbakery@mail.ru">kikiasonasbakery@mail.ru</a>
        </span>
      </section>
      <section className="mobileLinkBox" onClick={openMobile}>
        <span className="mobileLinkBox-image"></span>
      </section>
      <section className="mobileNavigateButton" onClick={handleAnchorLinkClick}>
        <img
          className="linkIcon_img-mobile"
          src={location.pathname === "/products" ? homePageIcon : buyIcon}
          alt={
            location.pathname === "/products"
              ? "Ссылка на главную страницу"
              : "Ссылка на каталог товаров"
          }
        />
      </section>
      <section className="mobileLinkBox-open">
        <div className="mobileLinkBox-open-icon-box">
          <Link
            to="https://t.me/kikiasonasbakery"
            className="mobileLinkBox-linkIcon"
            target="_blank"
          >
            <img
              className="linkIcon_img"
              src={telegramGroup}
              alt="Ссылка телеграм"
            />
            <p className="linkIconTitle">telegram чат</p>
          </Link>
          <Link to="https://vk.com/kikiasonasbakery" className="mobileLinkBox-linkIcon" target="_blank">
            <img className="linkIcon_img" src={vk} alt="Ссылка телеграм" />
            <p className="linkIconTitle">vk группа</p>
          </Link>
          <Link
            to="https://t.me/asonasbakerykiki"
            className="mobileLinkBox-linkIcon"
            target="_blank"
          >
            <img
              className="linkIcon_img"
              src={telegramGroup}
              alt="Ссылка телеграм"
            />
            <p className="linkIconTitle">telegram группа</p>
          </Link>
          <a className="linkIconTitle" href="mailto:kikiasonasbakery@mail.ru">kikiasonasbakery@mail.ru</a>
        </div>
        <span className="iconClose" onClick={closeMobile}></span>
      </section>
    </>
  );
}

export default LinkTab;

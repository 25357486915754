import { useEffect } from "react";
function ProductCardList({
  cardArray,
  CardItem,
  setbuyListActive,
  cardArrayFilter,
  setcardArrayFilter,
  setOpenDescriptionCard,
  setDescriptionTitle,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("open");
  }, []);

  function arrayBakery() {
    const bakery = cardArray.filter((x) => {
      if (x.type.includes("bakery")) {
        return x;
      }
    });
    return setcardArrayFilter(bakery);
  }
  function arrayJem() {
    const jem = cardArray.filter((x) => {
      if (x.type.includes("jem")) {
        return x;
      }
    });
    return setcardArrayFilter(jem);
  }
  function arraySouse() {
    const souse = cardArray.filter((x) => {
      if (x.type.includes("souse")) {
        return x;
      }
    });
    return setcardArrayFilter(souse);
  }
  function arrayGiftBox() {
    const giftBox = cardArray.filter((x) => {
      if (x.type.includes("giftBox")) {
        return x;
      }
    });
    return setcardArrayFilter(giftBox);
  }
  function arraySalads() {
    const salads = cardArray.filter((x) => {
      if (x.type.includes("salads")) {
        return x;
      }
    });
    return setcardArrayFilter(salads);
  }
  return (
    <div className="productCardList">
      <nav className="productCardList-navigation">
        <p className="navigation-title" onClick={arrayJem}>
          Варенье
        </p>
        <p className="navigation-title" onClick={arrayBakery}>
          выпечка
        </p>
        <p className="navigation-title" onClick={arraySouse}>
          Соусы
        </p>
        <p className="navigation-title" onClick={arraySalads}>
          салаты
        </p>
        <p className="navigation-title" onClick={arrayGiftBox}>
          подарочные наборы
        </p>
      </nav>
      <section id="bakeryList" className="productGrid">
        {cardArrayFilter.map((item) => {
          return (
            <CardItem
              key={item.id}
              item={item}
              setbuyListActive={setbuyListActive}
              setOpenDescriptionCard={setOpenDescriptionCard}
              setDescriptionTitle={setDescriptionTitle}
            />
          );
        })}
      </section>
    </div>
  );
}

export default ProductCardList;

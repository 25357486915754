import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../Main/Main";
import banka from "../../images/КарточкиТовара/4.webp";
import tikva from "../../images/КарточкиТовара/11.webp";
import adjika from '../../images/КарточкиТовара/49.webp';
import salatic from '../../images/КарточкиТовара/32.webp'
import DeliveryBox from "../DeliveryBox/DeliveryBox";
import CardRoad from "../cardRoad/cardRoad";

function ProductsPage({ cardArray, cardArrayFilter, setcardArrayFilter }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function arrayBakery() {
    const bakery = cardArray.filter((x) => {
      if (x.type.includes("bakery")) {
        return x;
      }
    });
    console.log(bakery)
    setcardArrayFilter(bakery);
    return navigate("/products");
  }
  function arrayJem() {
    const jem = cardArray.filter((x) => {
      if (x.type.includes("jem")) {
        return x;
      }
    });
    setcardArrayFilter(jem);
    return navigate("/products");
  }
  function arraySouse() {
    const souse = cardArray.filter((x) => {
      if (x.type.includes("souse")) {
        return x;
      }
    });
    setcardArrayFilter(souse);
    return navigate("/products");
  }
  function arraySalads() {
    const salads = cardArray.filter((x) => {
      if (x.type.includes("salads")) {
        return x;
      }
    });
    setcardArrayFilter(salads);
    return navigate("/products");
  }
  return (
    <>
      <Main />
      <article className="stringRun">
        <div className="items-wrap">
          <div className="items marquee">
            <p className="item">варенье</p>
            <p className="item">соусы</p>
            <p className="item">выпечка</p>
            <p className="item">салаты</p>
            <p className="item">подарочные наборы</p>
            <p className="item">доставка</p>
          </div>
          <div aria-hidden="true" className="items marquee">
            <p className="item">варенье</p>
            <p className="item">соусы</p>
            <p className="item">выпечка</p>
            <p className="item">салаты</p>
            <p className="item">подарочные наборы</p>
            <p className="item">доставка</p>
          </div>
        </div>
      </article>
      <CardRoad/>
      <DeliveryBox />
      <ul id="sectionBuy" className="ProductsPage">
        <li className="article-card" onClick={arrayBakery}>
          <div className="content">
            <h3 className="title">Выпечка</h3>
            <span className="pulseIcon"></span>
          </div>
          <img src={tikva} alt="Изображение Выпичка" />
        </li>
        <li className="article-card" onClick={arrayJem}>
          <div className="content">
            <h3 className="title">Варенье</h3>
            <span className="pulseIcon"></span>
          </div>
          <img src={banka} alt="Изображение Варенье" />
        </li>
        <li className="article-card" onClick={arraySalads}>
          <div className="content">
            <h3 className="title">Салаты</h3>
            <span className="pulseIcon"></span>
          </div>
          <img src={salatic} alt="Изображение Варенье" />
        </li>
        <li className="article-card" onClick={arraySouse}>
          <div className="content">
            <h3 className="title">Соусы</h3>
            <span className="pulseIcon"></span>
          </div>
          <img src={adjika} alt="Изображение Варенье" />
        </li>
      </ul>
    </>
  );
}

export default ProductsPage;

import { React, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { register } from "swiper/element/bundle";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProductsPage from "../ProductsPage/ProductsPage";
import CardArray from "../../cardData/cardData";
import Footer from "../Footer/Footer";
import LinkTab from "../LinkTab/LinkTab";
import ProductCardList from "../ProductCardList/ProductCardList";
import CardItem from "../CardItem/CardItem";
import BuyList from "../BuyList/BuyList";
import CardDescription from "../CardItem/CardDescription";
import logo from "../../images/логотипСайт-1.png";

function App() {
  gsap.registerPlugin(ScrollTrigger);
  register();
  useEffect(() => {
    
    setcardArrayFilter(CardArray);

    gsap.fromTo(
      ".miniLogo",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: ".logoBox",
          start: "bottom top",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      ".descriptionBrand ",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: ".descriptionBrand ",
          start: "top bottom",
          end: "bottom 80%",
        },
      }
    );
  }, []);

  const [buyListActive, setbuyListActive] = useState(false);
  const [cardArrayFilter, setcardArrayFilter] = useState([]);
  const [openDescriptionCard, setOpenDescriptionCard] = useState(false);
  const [descriptionTitle, setDescriptionTitle] = useState("");

  return (
    <div className="page">
      <div className="logoBox">
        <img className="logoMain" src={logo} alt="Логотип" />
        <span className="miniLogo"></span>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <ProductsPage
              cardArray={CardArray}
              setcardArrayFilter={setcardArrayFilter}
              cardArrayFilter={cardArrayFilter}
            />
          }
        />
        <Route
          path="*"
          element={
            <ProductsPage
              cardArray={CardArray}
              setcardArrayFilter={setcardArrayFilter}
              cardArrayFilter={cardArrayFilter}
            />
          }
        />
        <Route
          path="/products"
          element={
            <ProductCardList
              cardArray={CardArray}
              CardItem={CardItem}
              setbuyListActive={setbuyListActive}
              cardArrayFilter={cardArrayFilter}
              setcardArrayFilter={setcardArrayFilter}
              setOpenDescriptionCard={setOpenDescriptionCard}
              setDescriptionTitle={setDescriptionTitle}
            />
          }
        ></Route>
      </Routes>
      <Footer />
      <LinkTab />
      {buyListActive && <BuyList buyListActive={setbuyListActive} />}
      {openDescriptionCard && (
        <CardDescription
          descriptionTitle={descriptionTitle}
          setOpenDescriptionCard={setOpenDescriptionCard}
        />
      )}
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";
import vkQR from "../../images/qr vk site.png";
import tgQR from "../../images/qr tg site.png";

function BuyList({ buyListActive }) {
  function closeBuyList() {
    buyListActive(false);
  }
  return (
    <section className="buyListBoxPage" onClick={()=>buyListActive(false)}>
      <article className="buyListBox" onClick={(e) => e.stopPropagation()}>
        <h5 className="buyIntruction">
          Рады видеть вас на этапе покупки! Для осуществления покупки напишите
          нам в telegram или vk любым удобным вам способом, отсканировав QR-код
          или перейдя по прямой ссылке.
        </h5>

        <section className="deliveryTextBox">
          <p className="deliveryText">
            Доставляем продукцию только по городу Владивосток.
            <br />
            Сумма доставки 300 &#8381;.
          </p>
          <p className="deliveryText">
            Оплата только при получении после проверки продукции.
            <br />
            Способы оплаты: наличный расчет, безналичная оплата переводом на
            карту.
          </p>
        </section>

        <ul className="buyList">
          <li className="buyListContact">
            <img
              className="buyListContactImg"
              src={tgQR}
              alt="Изображение QR кода"
            ></img>
            <Link
              className="buyListContactLink"
              to="https://t.me/kikiasonasbakery"
              target="_blank"
            >
              telegram
            </Link>
            <span className="pulseIcon"></span>
          </li>
          <li className="buyListContact">
            <img
              className="buyListContactImg"
              src={vkQR}
              alt="Изображение QR кода"
            ></img>
            <Link
              className="buyListContactLink"
              to="https://vk.com/kikiasonasbakery"
              target="_blank"
            >
              vk
            </Link>
            <span className="pulseIcon"></span>
          </li>
        </ul>
        <span className="iconClose" onClick={closeBuyList}></span>
      </article>
    </section>
  );
}

export default BuyList;

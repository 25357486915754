import deliveryBoxIcon from "../../images/Box.png";
import gsap from "gsap";
function DeliveryBox() {
  return (
    <section className="deliveryBox">
      <img
        src={deliveryBoxIcon}
        alt="Иконка доставки"
        className="deliveryBoxIcon"
      />
      <article className="deliveryTextBox">
        <p className="deliveryTitle">Доставка и Оплата</p>
        <p className="deliverySubTitle">Условия доставки</p>
        <p className="deliveryText">
          Доставляем продукцию только по городу Владивосток.
          <br />
          Сумма доставки 300 &#8381;.
        </p>
        <p className="deliverySubTitle">Условия оплаты</p>
        <p className="deliveryText">
          Оплата только при получении после проверки продукции.
          <br />
          Способы оплаты: наличный расчет, безналичная оплата переводом на
          карту.
        </p>
      </article>
    </section>
  );
}

export default DeliveryBox;

function Main() {
  return (
    <section className="contentBox">
      <h1 className="helloTitle">
        KIKI Asona's Bakery
        <br /> варенье, соусы, выпечка.
        <br /> Доставка Владивосток
      </h1>
      <h2 className="helloTitleMessage">
        На нашем сайте собрана вся интересующая вас информация.
      </h2>
      <h3 className="descriptionBrand">
        Kiki Asona`s bakery (пекарня Асоны "Кики") во Владивостоке с радостью
        производит для Вас вкусные авторские варенье, соусы и выпечку. Мы
        готовим только из натуральных продуктов, без консервантов и
        искусственных добавок (состав вы можете найти в карточке товара или на
        таре продукции).  Для заказа напишите нам в Telegram чате, ссылку можете
        найти в блоке контакты. Так же советуем подписаться на нашу группу в
        Telegram для отслеживания новинок и актуальных новостей о нашей
        продукции.
      </h3>
      <span className="mainLine"></span>
    </section>
  );
}

export default Main;

import { React, useState, useEffect } from "react";
import cardRoad from "../../cardData/cardImageRoad";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function CardRoad() {
  const [cardRoadArray, setCardRoadArray] = useState([]);

  useEffect(() => {
    const randomArrayCard = cardRoad.sort(() => Math.random() - 0.5);
    setCardRoadArray(randomArrayCard)
    if (setCardRoadArray.length > 0) {
      const swiper = new Swiper(".swiperRoad", {
        slidesPerView: "auto",
        speed: 400,
        loop: true,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      return;
    }
    
  }, []);

  return (
    <div className="swiperRoad">
      <div className="swiper-wrapper">
        {cardRoadArray.map((card) => {
          return (
            <img
              key={card.id}
              className="swiperCardRoad swiper-slide"
              src={card.url}
              alt="изображение карточки товара"
            />
          );
        })}
      </div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
}

export default CardRoad;

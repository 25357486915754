import logo from '../../images/logoFooter.png'
function Footer() {
  return (
    <div className="footerBlock">
        <img className="footerLogo" src={logo} alt='Логотип'/>
        <p className="footerTitle">kikiasonasbakery@mail.ru</p>
      <p className="footerTitle">© Все права защищены.</p>
      <a className="footerTitle" href="https://waboutique.ru/">waboutique.ru</a>
    </div>
  );
}

export default Footer;

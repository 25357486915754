import { React, useEffect, useState } from "react";
import Swiper from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function CardItem({
  item,
  setbuyListActive,
  setOpenDescriptionCard,
  setDescriptionTitle,
}) {
  const [cardImageArray, setCardImageArray] = useState([]);
  const [lala, setLala] = useState(false);

  useEffect(() => {
    setCardImageArray(item.url);
    if (cardImageArray.length > 0) {
      const swiper = new Swiper(".swiper", {
        speed: 400,
        slidesPerView: "auto",
        loop: true,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      });
      return setLala(true);
    }
  }, [cardImageArray]);

  function openDescription() {
    setDescriptionTitle(item.description);
    setOpenDescriptionCard(true);
  }
  function buyCard() {
    setbuyListActive(true);
  }

  return (
    <article id={item.id} className="productGrid-cardItem">
      <div className="swiper">
        <div className="swiper-wrapper">
          {lala ? (
            cardImageArray.map((card) => {
              return (
                <img
                  key={card.id}
                  className="productGrid-cardItem-img swiper-slide"
                  src={card.url}
                  alt="изображение карточки товара"
                />
              );
            })
          ) : (
            <span className="loader"></span>
          )}
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
      <span className="descriptionProduct" onClick={openDescription}>
        Описание
      </span>
      <span className="numberOfPieces">{item.numberOfPieces}</span>
      <div className="productGrid-cardItem-card-description">
        <h4 className="productGrid-cardItem-card-title">{item.title}</h4>
        <p className="productGrid-cardItem-card-price">{item.price}</p>
      </div>
      <span className="elementBuy" onClick={buyCard}>
        Купить
      </span>
    </article>
  );
}

export default CardItem;
